import { FunctionComponent, ReactNode } from 'react';
import Link from 'next/link';
import cn from 'classnames';

import Image from 'components/Image/Image';
import { commonCls, mobileCls, tabletCls } from 'settings/uiKitClasses';

import styles from './DevFeatureLink.module.scss';

interface IDevFeatureLinkProps {
    title: string;
    href: string;
    alt: string;
    srcX1: string;
    srcX2: string;
    className?: string;
    children?: ReactNode;
}

export const DevFeatureLink: FunctionComponent<IDevFeatureLinkProps> = ({
    title,
    href,
    alt,
    srcX1,
    srcX2,
    children,
    className,
}) => (
    <Link href={href}>
        <a className={cn(styles.Link, className)}>
            <Image className={cn(styles.Icon)} src={srcX1} srcSet={`${srcX2} 2x`} alt={alt} width={80} height={80}>
                <source srcSet={`${srcX1} 1x, ${srcX2} 2x`} type="image/png" />
            </Image>
            <article className={styles.Article}>
                <h4 className={cn(styles.Title, commonCls.Headline5, mobileCls.Headline7)}>{title}</h4>
                <p className={cn(styles.Description, commonCls.Body1, tabletCls.Body2, mobileCls.Body3)}>{children}</p>
            </article>
        </a>
    </Link>
);
