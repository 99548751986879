import React from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import Image from 'components/Image/Image';
import FeedbackButton from 'components/FeedbackButton/FeedbackButton';
import LayoutContainer from 'components/LayoutContainer/LayoutContainer';
import { ROUTES } from 'settings/routingConstants';
import { commonCls, mobileCls } from 'settings/uiKitClasses';

import styles from './Footer.module.scss';

export const Footer: React.FC = () => {
    return (
        <LayoutContainer rootClassName={styles.footerContainer} grayBg shiftedRight>
            <footer className={styles.root}>
                <div className={classnames(styles.footerInfo, styles.footerInfoAbout)}>
                    <Image
                        className={styles.footerLogo}
                        src="/images/ui/logoDiginavis.svg"
                        alt="diginavis"
                        width={168}
                        height={24}
                    />
                    <em className={classnames(styles.address, commonCls.Body1, mobileCls.Body2)}>
                        ООО «Дигинавис»
                        <br />
                        420500, г.&nbsp;Иннополис, ул.&nbsp;Университетская, дом&nbsp;7, 7&nbsp;этаж,&nbsp;офис&nbsp;703
                    </em>
                </div>
                <div className={styles.footerLinksCompany}>
                    <h5 className={styles.footerLinksHeader}>Компания</h5>
                    <ul>
                        <li className={styles.footerLink}>
                            <Link href={ROUTES.ABOUT}>
                                <a>О нас</a>
                            </Link>
                        </li>
                        <li className={styles.footerLink}>
                            <Link href={ROUTES.NEWS}>
                                <a>Новости</a>
                            </Link>
                        </li>
                        <li className={styles.footerLink}>
                            <Link href={ROUTES.SOLUTION}>
                                <a>Наше решение</a>
                            </Link>
                        </li>
                        <li className={styles.footerLink}>
                            <Link href={ROUTES.EPR}>
                                <a>ЭПР в Иннополисе</a>
                            </Link>
                        </li>
                        <li className={styles.footerLink}>
                            <Link href={ROUTES.PLATFORM}>
                                <a>Платформа</a>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className={styles.footerLinksContacts}>
                    <h5 className={styles.footerLinksHeader}>Контакты</h5>
                    <ul>
                        <li className={styles.footerLink}>
                            <FeedbackButton>Обратная связь</FeedbackButton>
                        </li>
                        <li className={styles.footerLink}>
                            <Link href={ROUTES.ADDRESS}>
                                <a>Адрес</a>
                            </Link>
                        </li>
                    </ul>
                </div>
            </footer>
        </LayoutContainer>
    );
};

export default Footer;
