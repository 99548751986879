import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';

import LargeIconButton from 'components/LargeIconButton/LargeIconButton';
import { commonCls } from 'settings/uiKitClasses';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import styles from './Carousel.module.scss';

const REM = 16;

interface ICarouselProps {
    elements: React.ReactNode[];
    emptyTitle?: string;
    className?: string;
    listClassName?: string;
    verticalForMobile?: boolean;
}

export const Carousel: React.FC<ICarouselProps> = ({
    elements,
    emptyTitle,
    className,
    listClassName,
    verticalForMobile,
}) => {
    const [step, setStep] = useState(0);
    const [arrowRightIsDisabled, setArrowRightDisableState] = useState(false);
    const [buttonGroupIsVisible, toggleButtonGroup] = useState(false);
    const scrollContainerRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        const parent = scrollContainerRef.current?.parentElement;
        if (!scrollContainerRef.current || !parent) {
            return () => {};
        }

        const resizeHandler = () => {
            const elW = scrollContainerRef.current?.offsetWidth ?? Number.NaN;
            const parentW = parent.offsetWidth;
            toggleButtonGroup(elW > parentW);
            setStep(0);
        };

        resizeHandler();
        window.addEventListener('resize', resizeHandler);

        return () => window.removeEventListener('resize', resizeHandler);
    }, [scrollContainerRef]);

    useEffect(() => {
        const parentW = scrollContainerRef.current?.parentElement?.offsetWidth;
        if (!scrollContainerRef.current || !parentW) {
            return;
        }

        const newOffset = scrollContainerRef.current.querySelectorAll('li')[step].offsetLeft - REM ?? 0;
        setArrowRightDisableState(step === elements.length - 1);

        scrollContainerRef.current.style.left = `-${newOffset}px`;
    }, [scrollContainerRef, step, elements]);

    if (elements.length === 0) {
        return <div className={classnames(styles.emptyTitle, commonCls.Headline5, className)}>{emptyTitle}</div>;
    }

    return (
        <div className={classnames(styles.root, className)}>
            <ul
                ref={scrollContainerRef}
                className={classnames(styles.list, listClassName, { [styles.verticalForMobile]: verticalForMobile })}
            >
                {elements.map((element, i) => (
                    <li key={i}>{element}</li>
                ))}
            </ul>
            {buttonGroupIsVisible && (
                <div className={styles.buttonGroup}>
                    <LargeIconButton disabled={step === 0} onClick={() => setStep(_step => _step - 1)}>
                        <ArrowBackIcon />
                    </LargeIconButton>
                    <LargeIconButton disabled={arrowRightIsDisabled} onClick={() => setStep(_step => _step + 1)}>
                        <ArrowForward />
                    </LargeIconButton>
                </div>
            )}
        </div>
    );
};

export default Carousel;
