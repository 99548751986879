import Link from 'next/link';
import classnames from 'classnames';

import Image from 'components/Image/Image';
import Carousel from 'components/Carousel/Carousel';
import Footer from 'components/Footer/Footer';
import LayoutContainer from 'components/LayoutContainer/LayoutContainer';
import LinkButton from 'components/LinkButton/LinkButton';
import { DevFeatureLink } from 'components/DevFeatureLink/DevFeatureLink';
import { OUTER_LINKS, ROUTES } from 'settings/routingConstants';
import { getSortedNewsData } from 'services/DataFething/news';
import { ReturnPromiseType } from 'models/Helpers/Helpers';
import { commonCls, mobileCls, tabletCls } from 'settings/uiKitClasses';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import styles from './index.module.scss';

export const Home: React.FC<ReturnPromiseType<typeof getStaticProps>['props']> = ({ allNewsData }) => {
    return (
        <>
            <main>
                <section className={styles.featuresSection}>
                    <LayoutContainer rootClassName={styles.features}>
                        <h3
                            className={classnames(
                                styles.featuresTitle,
                                commonCls.Headline2,
                                tabletCls.Headline3,
                                mobileCls.Headline5,
                            )}
                        >
                            Системы для пользователей
                        </h3>
                        <ul className={styles.userFeaturesList}>
                            <li className={styles.userFeaturesListItem}>
                                <Link href={OUTER_LINKS.avtm}>
                                    <a className={styles.userFeatureLink}>
                                        <h4 className={classnames(commonCls.Headline3, tabletCls.Headline5)}>
                                            Карта воздушного пространства РФ
                                        </h4>
                                        <p className={classnames(commonCls.Subtitle1, mobileCls.Body2)}>
                                            Актуальное состояние структуры воздушного пространства РФ, аэродромы
                                            и зоны ограничения полетов БВС. Покажем, где можно летать на дроне
                                            без согласований
                                        </p>
                                    </a>
                                </Link>
                            </li>
                            <li className={styles.userFeaturesListItem}>
                                <Link href={OUTER_LINKS.regDemo}>
                                    <a className={styles.userFeatureLink}>
                                        <h4 className={classnames(commonCls.Headline3, tabletCls.Headline5)}>
                                            Полноформатная UTM&#8209;система
                                        </h4>
                                        <p className={classnames(commonCls.Subtitle1, mobileCls.Body2)}>
                                            Полный набор решений для диспетчеров БВС: обработка заявок на ИВП,
                                            предупреждение инцидентов, мониторинг БВС в режиме реального времени,
                                            просмотр архивных полетов
                                        </p>
                                    </a>
                                </Link>
                            </li>
                        </ul>
                    </LayoutContainer>
                    <LayoutContainer rootClassName={styles.features}>
                        <h3
                            className={classnames(
                                styles.featuresTitle,
                                commonCls.Headline2,
                                tabletCls.Headline3,
                                mobileCls.Headline5,
                            )}
                        >
                            Решения для разработчиков
                        </h3>
                        <ul className={styles.devFeaturesList}>
                            <li>
                                <DevFeatureLink
                                    alt=""
                                    href={`${ROUTES.SERVICES}?tab=ElevationAPI`}
                                    srcX1="/images/ui/elevationApiIcon_1x.png"
                                    srcX2="/images/ui/elevationApiIcon_2x.png"
                                    title="Elevation API"
                                >
                                    Сервис определения высот предоставляет интерфейс для запроса данных о высотах земной
                                    поверхности над средним уровнем моря для всей территории РФ
                                </DevFeatureLink>
                            </li>
                            <li>
                                <DevFeatureLink
                                    alt=""
                                    href={`${ROUTES.SERVICES}?tab=AirspaceDS`}
                                    srcX1="/images/ui/airspaceDSIcon_1x.png"
                                    srcX2="/images/ui/airspaceDSIcon_2x.png"
                                    title="Airspace DS"
                                >
                                    Источник данных о структуре воздушного пространства -режимы, расписания, контактная
                                    информация и удобный доступ в цифровом формате
                                </DevFeatureLink>
                            </li>
                            <li>
                                <DevFeatureLink
                                    alt=""
                                    href={`${ROUTES.SERVICES}?tab=TileApi`}
                                    srcX1="/images/ui/tileApiIcon_1x.png"
                                    srcX2="/images/ui/tileApiIcon_2x.png"
                                    title="Tile API"
                                >
                                    Векторное представление данных структуры воздушного пространства для построения
                                    систем визуализации при планировании и диспетчеризации
                                </DevFeatureLink>
                            </li>
                            <li>
                                <DevFeatureLink
                                    alt=""
                                    href={`${ROUTES.SERVICES}?tab=MetarApi`}
                                    srcX1="/images/ui/metarApiIcon_1x.png"
                                    srcX2="/images/ui/metarApiIcon_2x.png"
                                    title="METAR API"
                                >
                                    Актуальная информация о погоде METAR для конкретного аэродрома или одновременно
                                    для всех доступных (данные обновляются раз в час)
                                </DevFeatureLink>
                            </li>
                        </ul>
                    </LayoutContainer>
                </section>
                <section className={styles.news}>
                    <LayoutContainer>
                        <article>
                            <h3 className={classnames(commonCls.Headline2, tabletCls.Headline3, mobileCls.Headline4)}>
                                Новости
                            </h3>
                            {allNewsData.length > 3 && (
                                <LinkButton href={ROUTES.NEWS} label={'Все новости'} mobileHiddelLabel />
                            )}
                        </article>
                        <Carousel
                            verticalForMobile
                            elements={allNewsData.map(newsData => (
                                <Link href={ROUTES.TO_NEWS(newsData.id)} key={newsData.id}>
                                    <a>
                                        <article className={styles.newsCarouselItem}>
                                            <h5 className={classnames(commonCls.Headline6, mobileCls.Headline7)}>
                                                <strong
                                                    className={classnames(commonCls.Headline3, mobileCls.Headline4)}
                                                >
                                                    {newsData.date.replace(/^\d{4}\-\d{2}\-(\d{2})$/, '$1')}
                                                </strong>
                                                <small>{newsData.date.replace(/^\d{4}\-(\d{2})\-\d{2}$/, '$1')}</small>
                                                <small>
                                                    {newsData.date.replace(/^(\d{4})\-\d{2}\-\d{2}$/, (_m, m1) =>
                                                        m1 === new Date().getFullYear().toString() ? '' : m1,
                                                    )}
                                                </small>
                                            </h5>
                                            <p className={classnames(commonCls.Headline5, mobileCls.Headline6)}>
                                                {newsData.title}
                                            </p>
                                            <div
                                                className={classnames(
                                                    styles.newsCarouselItemAdditionText,
                                                    commonCls.Button,
                                                )}
                                            >
                                                <span>Подробнее</span>
                                                <ArrowForwardIcon fontSize="small" />
                                            </div>
                                        </article>
                                    </a>
                                </Link>
                            ))}
                            emptyTitle="Новостей нет"
                            className={styles.newsCarousel}
                        />
                    </LayoutContainer>
                </section>
                <section className={styles.integrations}>
                    <LayoutContainer>
                        <h3 className={classnames(commonCls.Headline2, tabletCls.Headline3, mobileCls.Headline4)}>
                            Интеграции
                        </h3>
                        <p className={classnames(commonCls.Headline7, tabletCls.Subtitle1, mobileCls.Subtitle2)}>
                            Любой вендор БВС имеет возможность интегрироваться с платформой.
                            <br />
                            Мы предоставляем документацию, руководства, а также обеспечим технической консультацией на
                            всех этапах процесса.
                        </p>
                        <h4 className={classnames(commonCls.Headline6, tabletCls.Subtitle1, mobileCls.Subtitle2)}>
                            Уже интегрированы:
                        </h4>
                        <ul>
                            <li>
                                <article>
                                    <Image
                                        className={styles.logo}
                                        src="/images/ui/logoIU_1x.png"
                                        srcSet="/images/ui/logoIU_2x.png 2x"
                                        alt="innopolis university"
                                        width={280}
                                        height={64}
                                    >
                                        <source
                                            srcSet="/images/ui/logoIU_1x.webp 1x, /images/ui/logoIU_2x.webp 2x"
                                            type="image/webp"
                                        />
                                    </Image>
                                    <p className={commonCls.Body2}>
                                        Лаборатория робототехники АНО «Университет Иннополис» производит разработку
                                        разнообразных типов БВС и научные изыскания по безопасности полётов.
                                    </p>
                                    <p className={classnames(commonCls.Subtitle1, mobileCls.Subtitle2)}>
                                        Наземная система управления и бортовые системы имеют интеграцию с платформой.
                                    </p>
                                </article>
                            </li>
                            <li>
                                <article>
                                    <Image
                                        className={styles.logo}
                                        src="/images/ui/logoTiber_1x.png"
                                        srcSet="/images/ui/logoTiber_2x.png 2x"
                                        alt="tiber"
                                        width={236}
                                        height={64}
                                    >
                                        <source
                                            srcSet="/images/ui/logoTiber_1x.webp 1x, /images/ui/logoTiber_2x.webp 2x"
                                            type="image/webp"
                                        />
                                    </Image>
                                    <p className={commonCls.Body2}>
                                        Компания производит полную линейку всех элементов беспилотных систем
                                        автоматического управления — от ультра-лёгких (от 0,2 кг) до тяжёлых (2800 кг)
                                        БВС.
                                    </p>
                                    <p className={classnames(commonCls.Subtitle1, mobileCls.Subtitle2)}>
                                        Бортовая система всех моделей обладает интеграцией с платформой AVTM.
                                    </p>
                                </article>
                            </li>
                        </ul>
                    </LayoutContainer>
                </section>
                <section className={styles.partners}>
                    <LayoutContainer>
                        <article>
                            <h3 className={classnames(commonCls.Headline2, tabletCls.Headline3, mobileCls.Headline4)}>
                                Партнеры
                            </h3>
                            <div className={styles.partnersListWrapper}>
                                <ul>
                                    <li>
                                        <a href={OUTER_LINKS.RussianPost} rel="noopener noreferrer" target="_blank">
                                            <Image
                                                src="/images/ui/logoRussianPost_1x.png"
                                                srcSet="/images/ui/logoRussianPost_2x.png 2x"
                                                alt="russian post"
                                                width={132}
                                                height={64}
                                                autoWidth
                                            >
                                                <source
                                                    srcSet="/images/ui/logoRussianPost_1x.webp 1x, /images/ui/logoRussianPost_2x.webp 2x"
                                                    type="image/webp"
                                                />
                                            </Image>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={OUTER_LINKS.Rostec} rel="noopener noreferrer" target="_blank">
                                            <Image
                                                src="/images/ui/logoRostec_1x.png"
                                                srcSet="/images/ui/logoRostec_2x.png 2x"
                                                alt="rostec"
                                                width={205}
                                                height={64}
                                                autoWidth
                                            >
                                                <source
                                                    srcSet="/images/ui/logoRostec_1x.webp 1x, /images/ui/logoRostec_2x.webp 2x"
                                                    type="image/webp"
                                                />
                                            </Image>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={OUTER_LINKS.Tiber} rel="noopener noreferrer" target="_blank">
                                            <Image
                                                src="/images/ui/logoTiber_1x.png"
                                                srcSet="/images/ui/logoTiber_2x.png 2x"
                                                alt="tiber"
                                                width={236}
                                                height={64}
                                                autoWidth
                                            >
                                                <source
                                                    srcSet="/images/ui/logoTiber_1x.webp 1x, /images/ui/logoTiber_2x.webp 2x"
                                                    type="image/webp"
                                                />
                                            </Image>
                                        </a>
                                    </li>
                                    <div className={styles.flexWrapper} />
                                    <li>
                                        <a href={OUTER_LINKS.IU} rel="noopener noreferrer" target="_blank">
                                            <Image
                                                src="/images/ui/logoIU_1x.png"
                                                srcSet="/images/ui/logoIU_2x.png 2x"
                                                alt="innopolis university"
                                                width={280}
                                                height={64}
                                                autoWidth
                                            >
                                                <source
                                                    srcSet="/images/ui/logoIU_1x.webp 1x, /images/ui/logoIU_2x.webp 2x"
                                                    type="image/webp"
                                                />
                                            </Image>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={OUTER_LINKS.CTL} rel="noopener noreferrer" target="_blank">
                                            <Image src="/images/ui/logoCTL.svg" alt="ctl" width={87} height={64} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={OUTER_LINKS.Aeronext} rel="noopener noreferrer" target="_blank">
                                            <Image
                                                src="/images/ui/logoAeronext_1x.png"
                                                srcSet="/images/ui/logoAeronext_2x.png 2x"
                                                alt="aeronext"
                                                width={368}
                                                height={64}
                                                autoWidth
                                            >
                                                <source
                                                    srcSet="/images/ui/logoAeronext_1x.webp 1x, /images/ui/logoAeronext_2x.webp 2x"
                                                    type="image/webp"
                                                />
                                            </Image>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </LayoutContainer>
                </section>
            </main>
            <Footer />
        </>
    );
};

export const getStaticProps = async () => {
    const allNewsData = getSortedNewsData();
    return {
        props: {
            allNewsData,
        },
    };
};

export default Home;
