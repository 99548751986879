import React from 'react';
import Link from 'next/link';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';

import { commonCls, tabletCls } from 'settings/uiKitClasses';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import styles from './LinkButton.module.scss';

interface ILinkButtonProps {
    label: string;
    href: string;
    className?: string;
    mobileHiddelLabel?: boolean;
    onClick?: () => void;
}

const LinkButtonLabel: React.FC<Pick<ILinkButtonProps, 'label' | 'mobileHiddelLabel'>> = ({
    label,
    mobileHiddelLabel,
}) => {
    return (
        <span
            className={classnames(styles.linkButtonLabel, commonCls.Headline5, tabletCls.Headline6, {
                [styles.mobileHidden]: mobileHiddelLabel,
            })}
        >
            {label}
        </span>
    );
};

const RegularLinkButton: React.FC<ILinkButtonProps> = ({ className, href, onClick, ...labelProps }) => {
    return (
        <Button
            component={'a'}
            href={href}
            className={classnames(className, styles.buttonRoot)}
            color="primary"
            rel={'noopener noreferrer'}
            target={'_blank'}
            endIcon={<ArrowForwardIcon className={styles.linkButtonIcon} />}
            onClick={onClick}
        >
            <LinkButtonLabel {...labelProps} />
        </Button>
    );
};

const AppLinkButton: React.FC<ILinkButtonProps> = ({ className, href, onClick, ...labelProps }) => {
    return (
        <Link href={href}>
            <a className={classnames(styles.appLinkWrapper, className)} tabIndex={-1}>
                <Button
                    className={styles.buttonRoot}
                    component={'div'}
                    color="primary"
                    endIcon={<ArrowForwardIcon className={styles.linkButtonIcon} />}
                    onClick={onClick}
                >
                    <LinkButtonLabel {...labelProps} />
                </Button>
            </a>
        </Link>
    );
};

export const LinkButton: React.FC<ILinkButtonProps> = props => {
    const Component = props.href.startsWith('/') ? AppLinkButton : RegularLinkButton;
    return <Component {...props} />;
};

export default LinkButton;
